@import "@/assets/scss/var.scss";
.detail {
    background: $bg-color-grey;
    &-title {
        font-size: $fontsize-lg;
        margin: $offset-v 0;
    }
    &-block {
        padding: $offset-base-lg;
        margin: $offset-v 0;
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .el-tag {
            margin-right: 5px;
        }

        &__state {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: auto;
            &-desc {
                margin-right: $offset-h;
            }
            &-service {
                cursor: pointer;
                &__icon {
                    width: 2em;
                    height: 2em;
                    vertical-align: middle;
                    fill: currentColor;
                    margin-bottom: $offset-v-sm;
                    color: $btn-color-fb;
                }
            }
        }
        &-col {
            flex: 1;
            padding-left: $offset-base-md;
            padding-right: $offset-base-md;
            &__info {
                margin: 0;
                padding: 0;
                &-title {
                    color: $txt-color-grey;
                    flex: 0 0 80px;
                }
                li {
                    display: flex;
                    align-items: center;
                }
                li + li {
                    margin-bottom: $offset-v;
                }
                li:first-child {
                    margin-bottom: $offset-v-lg;
                }
            }
        }
        &-col + &-col {
            border-left: 1px dotted #ddd;
        }
        &-col:first-child {
            padding-left: 0;
        }
    }
    &-goods{
        flex-basis: 65%;
    }
    &-foot {
        display: flex;
        justify-content: space-between;
        align-items: unset;
    }
    .align-items-normal {
        align-items: normal;
    }
    .cart {
        width: 65%;
        .cart-header-commodity {
            flex-basis: 50%;
        }
        .cart-header-num {
            text-align: left;
        }
    }
    .cart-list {
        align-items: unset;
    }
    .group-set {
        position: relative;
        .group-buy-btn {
            position: absolute;
            left: 20px;
            bottom: 20px;
            color: #fff;
        }
    }
    /deep/ .step {
        padding: 0;
        margin-bottom: 10px;
        margin-top: 10px;
        background: #fff;
        .exp-step li {
            &:first-child {
                padding-left: $offset-cell;
            }
            &:before {
                margin-top: $offset-v;
            }
            &:after {
                border-color: $border $border transparent transparent;
            }
            &.active {
                background: #ff8064;
                overflow: visible;
                margin-right: $offset-cell;
                padding-right: 0;
                color: #fff;
                &::after {
                    background: #ff8064;
                    border-color: #ff8064;
                    width: 100px;
                    height: 100px;
                    transform: scaleX(0.5) translate(66%, -50%) rotate(45deg);
                }
                &::before {
                    position: relative;
                    z-index: 1;
                }
                span {
                    position: static;
                    z-index: 1;
                    &:first-child:before {
                        top: 50%;
                    }
                }
            }
        }
        .exp-step li:last-child {
            margin-right: 0;
        }
        .exp-step li:last-child span:last-child {
            margin-bottom: 0px;
        }
    }
    .payInfoWin,.allowanceWin {
        /deep/ .el-dialog {
            .el-dialog__body {
                padding-top: 0;
            }
            .el-input__inner:focus {
                border-color: $border-primary;
            }
            .center {
                > div + div {
                    margin-top: 20px;
                }
                .avatar-uploader .el-upload {
                    border: 1px dashed #d9d9d9;
                    border-radius: 6px;
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                }
                .avatar-uploader .el-upload:hover {
                    border-color: $border-primary;
                }
                .avatar-uploader-icon {
                    font-size: 28px;
                    color: #8c939d;
                    width: 100%;
                    height: 178px;
                    line-height: 178px;
                    text-align: center;
                }
                .avatar {
                    width: 100%;
                    height: 178px;
                    display: block;
                    object-fit: cover;
                }
                .avatar-uploader .el-upload:hover {
                    border-color: $border-primary;
                }
                .el-upload--picture-card:hover,
                .el-upload:focus {
                    border-color: $border-primary;
                    color: $color-primary;
                }
                .btn-grp {
                    text-align: center;
                    margin-top: 30px;
                    .el-button {
                        min-width: 100px;
                    }
                }
            }
        }
    }
    /deep/ .el-dialog {
        .el-textarea__inner:focus {
            border-color: $border-primary;
        }
        .center {
            // padding: 0 1em;
            > ul {
                > li {
                    .label {
                        margin-bottom: 10px;
                    }
                }
                > li + li {
                    margin-top: 20px;
                }
            }
            .l1 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                > span:first-child {
                    width: 260px;
                }
            }
            .l6 {
                text-align: center;
                margin-top: 30px;
                > span {
                    padding: 10px 38px;
                    display: inline-block;
                    text-align: center;
                    font-size: 14px;
                    line-height: 1.5;
                    border: 1px solid transparent;
                    background: transparent;
                    cursor: pointer;
                    background: $btn-color-primary;
                    color: #fff;
                    width: auto;
                    height: 100%;
                }
                > span:first-child {
                    margin-right: 30px;
                    color: $color-primary;
                    border-color: $border-primary;
                    background: #fff;
                }
            }
        }
    }
    ol,
    ul,
    li {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        /* font:inherit; */
        font-weight: normal;
        vertical-align: baseline;
        list-style: none;
    }
}
